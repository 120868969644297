import React, { useState, useEffect } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'; // This is the question mark icon
import TimelineIcon from '@mui/icons-material/Timeline'; // engagement rate
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // engagement time
import PendingActionsIcon from '@mui/icons-material/PendingActions'; // for action
import AnalyticsIcon from '@mui/icons-material/Analytics'; // sessions
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices'; // top device
import SourceIcon from '@mui/icons-material/Source'; // source
import QueryStatsIcon from '@mui/icons-material/QueryStats'; // top query
import LinkIcon from '@mui/icons-material/Link'; // url
import SpinnerComponent from './SpinnerComponent';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Arrow icon
import { useTaskStatus } from './TaskStatusContext';
import { usePollTask } from './usePollTask';
import DOMPurify from 'dompurify';
import PulsingLoader from './PulsingLoader';


const roundedtable = {
    borderRadius: '10px', // You can adjust the radius value as needed
    overflow: 'hidden',
};

const arrowiconStyle = {
    fontSize: '.5rem', // Small icon size
};

// Function to sanitize input using DOMPurify
const sanitizeInput = (input) => {
// Use DOMPurify to clean the input
return DOMPurify.sanitize(input, { USE_PROFILES: { html: true } });
	};

const ProjectTable = ({ urls, addToEnhancePlan, projectId, selectedUrls, setSelectedUrls, urlsData, isPropertyIdMissing }) => {
    const [sortState, setSortState] = useState({ column: null, direction: null });
    const [data, setData] = useState([]);
    const [enhancePlanUrlsExist, setEnhancePlanUrlsExist] = useState(false);

    useEffect(() => {
        console.log('urlsData updated:', urlsData);
        if (urlsData.length > 0) {
            setData(urlsData);
        }
    }, [urlsData]);

    useEffect(() => {
        const enhancePlanUrls = urls.filter(url => url.is_in_enhance_plan);
        setEnhancePlanUrlsExist(enhancePlanUrls.length > 0);
    }, [urls]);

    const { taskStatuses } = useTaskStatus();
    const taskIds = [...new Set(urlsData.map(urlData => urlData.taskId))];
    usePollTask(taskIds);

	useEffect(() => {
	    const sortedBySessions = [...urls].sort((a, b) => {
	        const sessionsA = a.summary_metrics.sessions !== undefined ? a.summary_metrics.sessions : -Infinity;
	        const sessionsB = b.summary_metrics.sessions !== undefined ? b.summary_metrics.sessions : -Infinity;
	        return sessionsB - sessionsA;
	    });
	    setData(sortedBySessions);
	}, [urls]);

    const handleCheckboxChange = (urlId) => {
        setSelectedUrls((prevSelectedUrls) => {
            const newSelectedUrls = new Set(prevSelectedUrls);
            if (newSelectedUrls.has(urlId)) {
                newSelectedUrls.delete(urlId);
            } else {
                newSelectedUrls.add(urlId);
            }
            return newSelectedUrls;
        });
    };

	const handleSortChange = (columnName) => {
	    const isAsc = sortState.column === columnName && sortState.direction === 'asc';
	    const direction = isAsc ? 'desc' : 'asc';
	    setSortState({ column: columnName, direction });

	    const sortedData = [...data].sort((a, b) => {
	        // Use a fallback value if the property is missing.
	        const getValue = (obj, key) => {
	            const value = key.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
	            return value !== undefined ? value : (typeof value === 'string' ? '' : -Infinity);
	        };

	        const aVal = getValue(a, columnName);
	        const bVal = getValue(b, columnName);

	        // Check if sorting the URL column (alphabetical)
	        if (columnName === 'url') {
	            return direction === 'asc'
	                ? aVal.localeCompare(bVal)
	                : bVal.localeCompare(aVal);
	        }

	        // For numeric values (like sessions, engagement_time, etc.)
	        if (aVal < bVal) {
	            return direction === 'asc' ? -1 : 1;
	        }
	        if (aVal > bVal) {
	            return direction === 'asc' ? 1 : -1;
	        }
	        return 0;
	    });
	    setData(sortedData);
	};
    

    const renderSortArrow = (columnName) => {
        if (sortState.column === columnName) {
            return sortState.direction === 'asc' ? '↑' : '↓';
        }
        return '';
    };

    const oddRowStyle = {
        backgroundColor: '#191A23' // Light gray background for odd rows
    };
    const evenRowStyle = {
        backgroundColor: 'rgb(18 24 39 / var(--tw-bg-opacity))' // White background for even rows
    };

	const spinnerRows = Array.from({ length: Math.max(50, data.length) }, (_, index) => (
	  <tr key={`spinner-${index}`} style={index % 2 === 0 ? evenRowStyle : oddRowStyle} className="h-[175px] pt-12 pb-12 relative">
	    <td colSpan={8} className="relative">
	      <div className="relative h-full">
	        {/* The sheen effect will cover the entire row */}
	        <PulsingLoader />
	      </div>
	    </td>
	  </tr>
	));
   

    return (
        <table className="text-white w-full text-sm bg-gray-50 min-w-[1200px]">
            <thead className="text-sm h-[50px]">
                <tr className="sticky -top-10 bg-blue-900 z-10">
	<th 
	    onClick={() => handleSortChange('url')} 
	    style={{ width: '20%', position: 'relative' }} 
	    className="text-center bg-blue-900 text-gray-200 font-normal"
	>
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="The landing page URL.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <LinkIcon fontSize="inherit" />
                            </IconButton>
                            URL {renderSortArrow('url')}
                        </div>
                    </th>
                    <th onClick={() => handleSortChange('summary_metrics.sessions')} style={{ width: '12%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="Number of sessions involving the URL in the last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <AnalyticsIcon fontSize="inherit" />
                            </IconButton>
                            Sessions {renderSortArrow('summary_metrics.sessions')}
                        </div>
                    </th>
                    <th onClick={() => handleSortChange('summary_metrics.engagement_time')} style={{ width: '12%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="The total length of time the URL was in focus or was in the foreground across all sessions divided by the total number of active users. Last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <AccessTimeIcon fontSize="inherit" />
                            </IconButton>
                            Engagement Time {renderSortArrow('summary_metrics.engagement_time')}
                        </div>
                    </th>
                    <th onClick={() => handleSortChange('summary_metrics.engagement_rate')} style={{ width: '12%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="% of sessions that are 10 seconds or more, had two or more page views or a conversion event (such as a scroll). Last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <TimelineIcon fontSize="inherit" />
                            </IconButton>
                            Engagement Rate {renderSortArrow('summary_metrics.engagement_rate')}
                        </div>
                    </th>
                    <th style={{ width: '10%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="Top organic search query leading to the URL with % of total sessions in brackets. Last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <QueryStatsIcon fontSize="inherit" />
                            </IconButton>
                            Top Query
                        </div>
                    </th>
                    <th style={{ width: '10%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="Top source / medium leading to the URL with % of total sessions in brackets. Last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <SourceIcon fontSize="inherit" />
                            </IconButton>
                            Top Source
                        </div>
                    </th>
                    <th style={{ width: '10%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="Top device for users landing on the URL with % of total sessions in brackets. Last 90 days.">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <ImportantDevicesIcon fontSize="inherit" />
                            </IconButton>
                            Top Device
                        </div>
                    </th>
                    <th style={{ width: '15%', position: 'relative' }} className="text-center bg-blue-900 text-gray-200 font-normal">
                        <div style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}>
                            <Tooltip title="Add to your enhance plan or view recommendations to improve">
                                <IconButton size="small" style={{ color: 'grey' }} >
                                    <HelpOutlineIcon fontSize="small" style={{ fontSize: '0.7rem' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="flex items-center justify-center gap-x-0.5">
                            <IconButton size="small" style={{ color: 'white' }}>
                                <PendingActionsIcon fontSize="inherit" />
                            </IconButton>
                            Actions
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
							{!enhancePlanUrlsExist && urls.length > 0 ? (
							    spinnerRows.map((row, index) => (
							        data[index] ? (
							            <tr key={data[index].url_id} style={index % 2 === 0 ? evenRowStyle : oddRowStyle} className="h-[175px] pt-12 pb-12 min-w-[1200px]">
							                <td className="text-center max-w-xs pr-4 pl-4 pt-10 pb-10 break-words">
							                    {data[index].url ? data[index].url : 'No URL data'}
							                    {data[index]?.redirects?.length > 0 && (
							                        <p className="text-xs mt-2 text-gray-300">
							                            <ArrowForwardIosIcon style={arrowiconStyle} /> Redirects to:{" "}
							                            {data[index].redirects.map((redirect, i) => (
							                                <span key={i}>
							                                    {redirect.destination_url}{i < data[index].redirects.length - 1 ? ', ' : ''}
							                                </span>
							                            ))}
							                        </p>
							                    )}
							                </td>
                                <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                    {isPropertyIdMissing ? (
                                        <div>No data</div>
                                    ) :
                                        data[index]?.summary_metrics?.sessions || data[index]?.summary_metrics?.sessions === 0 ? (
                                            data[index].summary_metrics.sessions > 0 ? (
                                                <>
                                                    {data[index].summary_metrics.sessions.toLocaleString()}
                                                    <hr className="my-2" />
                                                    <div className="w-[100px] h-[50px] mx-auto">
                                                        <Sparklines data={data[index].daily_metrics.map(metric => metric.sessions)} width={100} height={50}>
                                                            <SparklinesLine color="#ADD8E6" />
                                                        </Sparklines>
                                                        <div className={`text-xs ${data[index].summary_metrics.percentage_difference_sessions > 0 ? 'text-green-200' : data[index].summary_metrics.percentage_difference_sessions < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                            {isNaN(data[index].summary_metrics.percentage_difference_sessions) ? 'N/A' : (() => {
                                                                const value = data[index].summary_metrics.percentage_difference_sessions * 100;
                                                                const fixedValue = value.toFixed(0);
                                                                const formattedValue = Number(fixedValue).toLocaleString('en-US');
                                                                const sign = data[index].summary_metrics.percentage_difference_sessions > 0 ? '+' : '';
                                                                return `${sign}${formattedValue}% vs avg`;
                                                            })()}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div>No data</div>
                                            )
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <SpinnerComponent />
                                            </div>
                                        )}
                                </td>
                                <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                    {isPropertyIdMissing ? (
                                        <div>No data</div>
                                    ) :
                                        data[index]?.summary_metrics?.sessions === 0 ? (
                                            <div>No data</div>
                                        ) : typeof data[index]?.summary_metrics?.engagement_time === 'number' && !isNaN(data[index].summary_metrics.engagement_time) ? (
                                            <>
                                                {Math.round(data[index].summary_metrics.engagement_time).toLocaleString()} seconds
                                                <hr className="my-2" />
                                                <div className="w-[100px] h-[50px] mx-auto">
                                                    <Sparklines data={data[index].daily_metrics.map(metric => metric.engagement_time)} width={100} height={50}>
                                                        <SparklinesLine color="#CDA2D3" />
                                                    </Sparklines>
                                                    <div className={`text-xs ${data[index].summary_metrics.percentage_difference_time > 0 ? 'text-green-200' : data[index].summary_metrics.percentage_difference_time < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                        {
                                                            isNaN(data[index].summary_metrics.percentage_difference_time)
                                                                ? 'N/A'
                                                                : `${data[index].summary_metrics.percentage_difference_time > 0 ? '+' : ''}${Number(data[index].summary_metrics.percentage_difference_time * 100).toFixed(0).toLocaleString('en-US')}% vs avg`
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <SpinnerComponent />
                                            </div>
                                        )}
                                </td>
                                <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                    {isPropertyIdMissing ? (
                                        <div>No data</div>
                                    ) :
                                        typeof data[index]?.summary_metrics?.engagement_rate === 'number' && data[index].summary_metrics.engagement_rate > 0 ? (
                                            <>
                                                {(data[index].summary_metrics.engagement_rate * 100).toFixed(0)}%
                                                <hr className="my-2" />
                                                <div className="w-[100px] h-[50px] mx-auto">
                                                    <Sparklines data={data[index].daily_metrics.map(metric => metric.engagement_rate * 100)} width={100} height={50}>
                                                        <SparklinesLine color="#FFFFE0" />
                                                    </Sparklines>
                                                    <div className={`text-xs ${data[index].summary_metrics.percentage_difference_rate > 0 ? 'text-green-200' : data[index].summary_metrics.percentage_difference_rate < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                        {
                                                            isNaN(data[index].summary_metrics.percentage_difference_rate)
                                                                ? 'N/A'
                                                                : `${data[index].summary_metrics.percentage_difference_rate > 0 ? '+' : ''}${Number(data[index].summary_metrics.percentage_difference_rate * 100).toFixed(0).toLocaleString('en-US')}% vs avg`
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        ) : data[index]?.summary_metrics?.engagement_rate === 0 ? (
                                            <div>No data</div>
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <SpinnerComponent />
                                            </div>
                                        )}
                                </td>
                                <td className="text-center pr-4 pl-4 pt-10 pb-10 max-w-xs break-words">
                                    {isPropertyIdMissing ? (
                                        <div>No data</div>
                                    ) :
                                        data[index]?.summary_metrics?.sessions === 0 || (data[index].top_search_queries?.length > 0 && data[index].top_search_queries[0].clicks === 0) || (data[index].top_search_queries?.length > 0 && data[index].top_search_queries[0].query === 'No data') ? (
                                            <div>No data</div>
                                        ) : data[index].top_search_queries?.length > 0 ? (
                                            <div key={data[index].top_search_queries[0].query}>
                                                {data[index].top_search_queries[0].query}
                                                <div className="text-xs">
                                                    {data[index].top_search_queries[0].click_percentage !== undefined ?
                                                        `(${data[index].top_search_queries[0].click_percentage.toFixed(0)}%)` :
                                                        "No Data"}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center items-center h-full">
                                                <SpinnerComponent />
                                            </div>
                                        )}
                                </td>
					<td className="text-center pr-4 pl-4 pt-10 pb-10">
					                    {isPropertyIdMissing ? (
					                        <div>No data</div>
					                    ) : data[index]?.summary_metrics?.sessions === 0 ? (
					                        <div>No data</div>
					                    ) : Array.isArray(data[index].top_source_medium) && data[index].top_source_medium.length > 0 ? (
					                        (() => {
					                            const filteredSourceMedium = data[index].top_source_medium.filter(entry => entry.source_medium !== 'Other');
					                            if (filteredSourceMedium.length > 0) {
					                                return (
					                                    <div>
					                                        {filteredSourceMedium[0].source_medium}
					                                        <div className="text-xs">({filteredSourceMedium[0].session_percentage.toFixed()}%)</div>
					                                    </div>
					                                );
					                            } else {
					                                return <div>No data</div>;
					                            }
					                        })()
					                    ) : (
					                        <div className="flex justify-center items-center h-full">
					                            <SpinnerComponent />
					                        </div>
					                    )}
					                </td>
                                
                                <td className="text-center pr-4 pl-4 pt-10 pb-10">
                                    {isPropertyIdMissing ? (
                                        <div>No data</div>
                                    ) : data[index]?.summary_metrics?.sessions === 0 ? (
                                        <div>No data</div>
                                    ) : data[index].top_device?.device_name ? (
                                        <div>
                                            {data[index].top_device.device_name}
                                            <div className="text-xs">({data[index].top_device.session_percentage.toFixed(0)}%)</div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center h-full">
                                            <SpinnerComponent />
                                        </div>
                                    )}
                                </td>
                                <td className="text-center pr-4 pl-4 pt-10 pb-10 text-sm">
                                    {taskStatuses[data[index].url_id] === 'pending' ? (
                                        <div className="flex justify-center items-center h-full">
                                            <SpinnerComponent />
                                        </div>
                                    ) : data[index].is_in_enhance_plan ? (
                                        <button
                                            className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            onClick={() => window.location.href = `/projects/${projectId}/${data[index].url_id}`}
                                        >
                                            View insight
                                        </button>
                                    ) : (
                                        <div className="text-center pr-4 pl-4 text-sm">
                                            <input
                                                type="checkbox"
                                                checked={selectedUrls.has(data[index].url_id)}
                                                onChange={() => handleCheckboxChange(data[index].url_id)}
                                            />
                                            <div className="text-sm">Add to Enhance Plan</div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ) : row
                    ))
                ) : (
                    data.map((url, index) => (
                        <tr key={url.url_id} style={index % 2 === 0 ? evenRowStyle : oddRowStyle} className="h-[175px] pt-12 pb-12">
                            <td className="text-center max-w-xs pr-4 pl-4 pt-10 pb-10 break-words">{url.url}
                                {url && url.redirects && url.redirects.length > 0 && (
                                    <p className="text-xs mt-2 text-gray-300"><ArrowForwardIosIcon style={arrowiconStyle} />Redirects to:{" "}
                                        {url.redirects.map((redirect, index) => (
                                            <span key={index}>
                                                {redirect.destination_url}{index < url.redirects.length - 1 ? ', ' : ''}
                                            </span>
                                        ))}
                                    </p>
                                )}
                            </td>

                            <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                {isPropertyIdMissing ? (
                                    <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
                                ) :
                                    url?.summary_metrics?.sessions || url?.summary_metrics?.sessions === 0 ? (
                                        url.summary_metrics.sessions > 0 ? (
                                            <>
                                                {url.summary_metrics.sessions.toLocaleString()}
                                                <hr className="my-2" />
                                                <div className="w-[100px] h-[50px] mx-auto">
                                                    <Sparklines data={url.daily_metrics.map(metric => metric.sessions)} width={100} height={50}>
                                                        <SparklinesLine color="#ADD8E6" />
                                                    </Sparklines>
                                                    <div className={`text-xs ${url.summary_metrics.percentage_difference_sessions > 0 ? 'text-green-200' : url.summary_metrics.percentage_difference_sessions < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                        {isNaN(url.summary_metrics.percentage_difference_sessions) ? 'N/A' : (() => {
                                                            const value = url.summary_metrics.percentage_difference_sessions * 100;
                                                            const fixedValue = value.toFixed(0);
                                                            const formattedValue = Number(fixedValue).toLocaleString('en-US');
                                                            const sign = url.summary_metrics.percentage_difference_sessions > 0 ? '+' : '';
                                                            return `${sign}${formattedValue}% vs avg`;
                                                        })()}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div>No data</div> // Show "No data" when sessions is 0
                                        )
                                    ) : (
                                        <div className="flex justify-center items-center h-full">
                                            <SpinnerComponent /> {/* Show spinner when sessions data is undefined or loading */}
                                        </div>
                                    )}
                            </td>

                            <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                {isPropertyIdMissing ? (
                                    <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
                                ) :
                                    url?.summary_metrics?.sessions === 0 ? (
                                        <div>No data</div>
                                    ) : typeof url?.summary_metrics?.engagement_time === 'number' && !isNaN(url.summary_metrics.engagement_time) ? (
                                        <>
                                            {Math.round(url.summary_metrics.engagement_time).toLocaleString()} seconds
                                            <hr className="my-2" />
                                            <div className="w-[100px] h-[50px] mx-auto">
                                                <Sparklines data={url.daily_metrics.map(metric => metric.engagement_time)} width={100} height={50}>
                                                    <SparklinesLine color="#CDA2D3" />
                                                </Sparklines>
                                                <div className={`text-xs ${url.summary_metrics.percentage_difference_time > 0 ? 'text-green-200' : url.summary_metrics.percentage_difference_time < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                    {
                                                        isNaN(url.summary_metrics.percentage_difference_time)
                                                            ? 'N/A'
                                                            : `${url.summary_metrics.percentage_difference_time > 0 ? '+' : ''}${Number(url.summary_metrics.percentage_difference_time * 100).toFixed(0).toLocaleString('en-US')}% vs avg`
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="flex justify-center items-center h-full">
                                            <SpinnerComponent />
                                        </div>
                                    )}
                            </td>

                            <td className="text-center text-sm pr-4 pl-4 pt-10 pb-10">
                                {isPropertyIdMissing ? (
                                    <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
                                ) :
                                    typeof url?.summary_metrics?.engagement_rate === 'number' && url.summary_metrics.engagement_rate > 0 ? (
                                        <>
                                            {(url.summary_metrics.engagement_rate * 100).toFixed(0)}%
                                            <hr className="my-2" />
                                            <div className="w-[100px] h-[50px] mx-auto">
                                                <Sparklines data={url.daily_metrics.map(metric => metric.engagement_rate * 100)} width={100} height={50}>
                                                    <SparklinesLine color="#FFFFE0" />
                                                </Sparklines>
                                                <div className={`text-xs ${url.summary_metrics.percentage_difference_rate > 0 ? 'text-green-200' : url.summary_metrics.percentage_difference_rate < 0 ? 'text-red-200' : 'text-gray-200'}`}>
                                                    {
                                                        isNaN(url.summary_metrics.percentage_difference_rate)
                                                            ? 'N/A'
                                                            : `${url.summary_metrics.percentage_difference_rate > 0 ? '+' : ''}${Number(url.summary_metrics.percentage_difference_rate * 100).toFixed(0).toLocaleString('en-US')}% vs avg`
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : url?.summary_metrics?.engagement_rate === 0 ? (
                                        <div>No data</div>
                                    ) : (
                                        <div className="flex justify-center items-center h-full">
                                            <SpinnerComponent />
                                        </div>
                                    )}
                            </td>

						<td className="text-center pr-4 pl-4 pt-10 pb-10 max-w-xs break-words">
								    {isPropertyIdMissing ? (
								        <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
								    ) : url?.summary_metrics?.sessions === 0 ? (
								        <div>No data</div> // Show "No data" if sessions are 0
								    ) : url.top_search_queries?.length > 0 ? (
								        // Sort queries with null or 0 clicks as the lowest priority
								        (() => {
								            const sortedQueries = [...url.top_search_queries].sort((a, b) => {
								                const aClicks = a.clicks || 0;
								                const bClicks = b.clicks || 0;
								                return bClicks - aClicks;
								            });

								            // After sorting, display the first query with clicks or "No data"
								            const topQuery = sortedQueries[0];

								            return topQuery.clicks > 0 ? (
								                <div>
								                    <span dangerouslySetInnerHTML={{ __html: sanitizeInput(topQuery.query) }} />
								                    <div className="text-xs">
												{topQuery.click_percentage !== undefined ? 
												        `(${topQuery.click_percentage.toFixed(0)}%)` : 
												        "No Data"}
								                        
								                    </div>
								                </div>
								            ) : (
								                <div>No data</div> // If all sorted queries have 0 clicks, show "No data"
								            );
								        })()
								    ) : (
								        <div className="flex justify-center items-center h-full">
								            <SpinnerComponent /> {/* Show spinner when data is loading */}
								        </div>
								    )}
								</td>

						<td className="text-center pr-4 pl-4 pt-10 pb-10">
								    {isPropertyIdMissing ? (
								        <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
								    ) : url?.summary_metrics?.sessions === 0 ? (
								        <div>No data</div>
								    ) : Array.isArray(url.top_source_medium) && url.top_source_medium.length > 0 ? (
								        (() => {
								            // Filter out entries where the source_medium is 'Other'
								            const filteredSourceMedium = url.top_source_medium.filter(entry => entry.source_medium !== 'Other');

								            if (filteredSourceMedium.length > 0) {
								                // Use the first element from the filtered array
								                return (
								                    <div>
								                        {filteredSourceMedium[0].source_medium}
								                        <div className="text-xs">
								                            ({filteredSourceMedium[0].session_percentage.toFixed()}%)
								                        </div>
								                    </div>
								                );
								            } else {
								                return <div>No data</div>; // No valid entries found
								            }
								        })()
								    ) : (
								        <div className="flex justify-center items-center h-full">
								            <SpinnerComponent />
								        </div>
								    )}
								</td>
                            <td className="text-center pr-4 pl-4 pt-10 pb-10">
                                {isPropertyIdMissing ? (
                                    <div>No data</div> // Show "No data" immediately if isPropertyIdMissing is true
                                ) : url?.summary_metrics?.sessions === 0 ? (
                                    <div>No data</div>
                                ) : url.top_device?.device_name ? (
                                    <div>
                                        {url.top_device.device_name}
                                        <div className="text-xs">({url.top_device.session_percentage.toFixed(0)}%)</div>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center h-full">
                                        <SpinnerComponent />
                                    </div>
                                )}
                            </td>

                            <td className="text-center pr-4 pl-4 pt-10 pb-10 text-sm">
                                {taskStatuses[url.url_id] === 'pending' ? (
                                    <div className="flex justify-center items-center h-full">
                                        <SpinnerComponent />
                                    </div>
                                ) : url.is_in_enhance_plan ? (
                                    <button
                                        className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        onClick={() => window.location.href = `/projects/${projectId}/${url.url_id}`}
                                    >
                                        View insight
                                    </button>
                                ) : (
                                    <div className="text-center pr-4 pl-4 text-sm">
                                        <input
                                            type="checkbox"
                                            checked={selectedUrls.has(url.url_id)}
                                            onChange={() => handleCheckboxChange(url.url_id)}
                                        />
                                        <div className="text-sm">Add to Enhance Plan</div>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))
                )}
                {urls.length === 0 && (
                    <tr>
                        <td colSpan="8" className="text-center text-gray-200 py-8 bg-darkBlue">
                            No results found
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default ProjectTable;