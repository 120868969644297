import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImportUrlsModal = ({ isOpen, onClose, projectId,  propertyId,  onUrlsSubmitted, userRole }) => {
    const [urls, setUrls] = useState('');
    const [error, setError] = useState('');
    const [totalUrls, setTotalUrls] = useState(0);
    const [initialRemainingUrls, setInitialRemainingUrls] = useState(0);
    const [remainingUrls, setRemainingUrls] = useState(0);

    useEffect(() => {
        const fetchUrlCount = async () => {
            try {
                const response = await axios.get(`https://api.contentenhance.com/api/url-count?projectId=${projectId}`, {
                    withCredentials: true
                });
                const data = response.data;
                const currentCount = data.count;
                const remainingCount = data.remaining_imports;

                setTotalUrls(currentCount);
                setRemainingUrls(remainingCount);
                setInitialRemainingUrls(remainingCount);
            } catch (error) {
                console.error('Error fetching URL count:', error);
                setError('Failed to fetch current URL count.');
            }
        };

        if (isOpen) {
            fetchUrlCount();
        }
    }, [isOpen, projectId]);

	const handleUrlsChange = (e) => {
	    const inputUrls = e.target.value;
	    setUrls(inputUrls);

	    const urlList = inputUrls.split('\n').map(url => url.trim()).filter(url => url !== '');
    
	    // Check if all URLs are valid
	    const isValid = urlList.every(url => url.startsWith('http://') || url.startsWith('https://'));
    
	    if (!isValid) {
	        setError('All URLs must start with http:// or https://');
	        return;
	    }

	    setError('');
	    const updatedRemainingUrls = initialRemainingUrls - urlList.length;
	    setRemainingUrls(updatedRemainingUrls >= 0 ? updatedRemainingUrls : 0);

	    if (updatedRemainingUrls < 0) {
	        setError(`You cannot import more than ${initialRemainingUrls + totalUrls} URLs.`);
	    }
	};

    const clearFormAndErrors = () => {
        setUrls('');
        setError('');
    };

    const handleClose = () => {
        clearFormAndErrors();
        onClose();
    };

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            handleClose();
        }
    };

	const handleSubmit = async () => {
	    if (!['admin', 'editor'].includes(userRole)) {
	        setError("You don't have permission to perform this action.");
	        return;
	    }

	    const urlList = urls.split('\n').map(url => url.trim()).filter(url => url !== '');
    
	    // Validate URLs before submission
	    const isValid = urlList.every(url => url.startsWith('http://') || url.startsWith('https://'));
	    if (!isValid) {
	        setError('All URLs must start with http:// or https://');
	        return;
	    }

	    const potentialTotalUrls = totalUrls + urlList.length;
	    if (potentialTotalUrls > totalUrls + initialRemainingUrls) {
	        setError(`You can only import up to ${initialRemainingUrls} more URLs.`);
	        return;
	    }

	    setError('');
	    try {
	        const response = await axios.post('https://api.contentenhance.com/api/submit-urls', {
	            urls: urlList,
	            projectId: projectId
	        }, {
	            headers: {
	                'Content-Type': 'application/json'
	            },
	            withCredentials: true
	        });

	        const submittedUrlIds = response.data.urlIds;
	        onUrlsSubmitted(submittedUrlIds);
	        setUrls('');
	        setError('');
	        onClose();
	    } catch (error) {
	        console.error('Error submitting URLs:', error);
	        setError(error.response?.data?.message || 'Failed to submit URLs');
	    }
	};

    return (
        <div id="modal-overlay" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[3000]" onClick={handleOverlayClick}>
            <div className="w-full max-w-3xl p-10 space-y-6 rounded-lg bg-gray-900 shadow-2xl" onClick={(e) => e.stopPropagation()}>
                <div className="text-center">
                    <h2 className="text-base text-gray-200 text-center">Import your own URLs</h2>
                    <div className="relative flex py-4 items-center">
                        <div className="flex-grow border-t border-gray-700"></div>
                    </div>
                    <div className="mt-2 px-7 py-3">
                        <textarea
                            className="appearance-none w-full h-72 px-3 py-2 border border-gray-600 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-200 bg-gray-700"
                            placeholder="Paste your URLs here - one per line. You will only get a snapshot of data for today for each URL unless you add it to your Enhance Plan."
                            value={urls}
                            onChange={handleUrlsChange}
                        ></textarea>
                    </div>
							{propertyId != null && (
							  <p className="text-gray-400 text-sm">
							    You can import up to {remainingUrls} more URLs today.
							  </p>
							)}
							
                    <div className="items-center px-4 py-3">
                        <button
                            id="ok-btn"
                            className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                        {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportUrlsModal;